















import TeamSectionKnowOurTeam from '@/components/TeamSectionKnowOurTeam.vue'
import TeamSectionAgents from '@/components/TeamSectionAgents.vue'
import TeamSectionServices from '@/components/TeamSectionServices.vue'
import SiteSection from '@/components/SiteSection.vue'
import { defineComponent } from '@vue/composition-api'

export default defineComponent({
  name: 'OurTeam',

  components: {
    TeamSectionKnowOurTeam,
    TeamSectionAgents,
    TeamSectionServices,
    SiteSection,
  },
})
