



















import SiteSection from '@/components/SiteSection.vue'
import TeamCardSkeleton from '@/components/TeamCardSkeleton.vue'
import TeamCard from '@/components/TeamCard.vue'
import { useAgents } from '@/composition/agents'
import { defineComponent } from '@vue/composition-api'

export default defineComponent({
  name: 'TeamSectionAgents',

  components: {
    SiteSection,
    TeamCardSkeleton,
    TeamCard,
  },

  setup() {
    const rowsPerPageAgents = 7
    const { data: agentList, isLoading: isLoadingAgentList } = useAgents({})
    return {
      agentList,
      rowsPerPageAgents,
      isLoadingAgentList,
    }
  },
})
