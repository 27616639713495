






























































































import { AgentViewModel } from '@/services/modules/agent'
import { formatPhoneNumber } from '@/utils/phone'
import { Location } from 'vue-router'
import { computed, defineComponent, PropType } from '@vue/composition-api'

export default defineComponent({
  name: 'TeamCard',

  props: {
    agent: {
      type: Object as PropType<AgentViewModel>,
      required: true,
    },
  },

  setup(props) {
    const phone = computed(() => formatPhoneNumber(props.agent.phone || ''))
    const cellPhone = computed(() => formatPhoneNumber(props.agent.cellPhone))

    const agentRouteTo = computed<Location>(() => ({
      name: 'AgentProfile',
      params: {
        agentKey: props.agent.agentKey.toString(),
      },
    }))

    return {
      phone,
      cellPhone,
      agentRouteTo,
    }
  },
})
